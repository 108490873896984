/**
 * Get redirects from the store and redirect the browser
 * if a match is found. We also redirect URLs ending in a
 * slash to avoid duplicate content.
 *
 * @param {*} to The 'to' route object
 *
 * @returns {void}
 */
export default defineNuxtRouteMiddleware(async (to) => {
	/**
	 * Check if a page exists for a given route
	 *
	 * @param {Object} to The 'to' route object
	 *
	 * @returns {Boolean} True if the page exists or false if not.
	 */
	const pageExists = async (to) => {
		const locale = to.name.split('_').at(-1);
		const pageFilename = useFilename(to, locale);

		let { data: pageData } = await useWebsiteFetch('pages', {
			query: { filename: pageFilename, language: locale },
		});

		// Double check the filename with the path, when the default language is in the requested URL
		// we may get a page with the wrong filename.
		if (Object.keys(pageData.value).length && pageData.value.filename === to.path) {
			return true;
		}

		return false;
	};

	const { redirects, fetchRedirects } = useRedirects();
	if (!redirects.value) {
		await fetchRedirects();
	}

	const foundRedirect = redirects.value?.find((item) => item.source === to.path.substring(1));

	// Don't redirect away from existing pages
	const exists = await pageExists(to);
	if (exists) {
		return null;
	}

	if (foundRedirect) {
		const destination = foundRedirect.destination.replace(/^\/|\/$/g, '');
		if (to.path.substring(1) !== destination) {
			return navigateTo(`/${destination}`, {
				redirectCode: foundRedirect.statusCode,
			});
		}
	} else if (!(to.path === '/') && to.path.endsWith('/')) {
		return navigateTo(to.path.slice(0, -1), { redirectCode: '301' });
	}

	// Temporary redirects for the holding page
	if (to.path.startsWith('/en')) {
		return navigateTo('/en', { redirectCode: '302' });
	} else {
		return navigateTo('/', { redirectCode: '302' });
	}
});
